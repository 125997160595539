import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Stack } from '@twilio-paste/stack';
import { AspectRatio } from '@twilio-paste/aspect-ratio';
import { InformationIcon } from '@twilio-paste/icons/esm/InformationIcon';
import { Text } from '@twilio-paste/text';
import Changelog from '@twilio-paste/tooltip/CHANGELOG.md';
import { useTooltipState, Tooltip } from '@twilio-paste/tooltip';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
import { StateHookTabsExample, NonPasteChildComponentExample } from '../../../component-examples/TooltipExamples';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/tooltip"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/tooltip/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/tooltip" storybookUrl="/?path=/story/components-tooltip--default" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using alpha components in your product</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Alpha components are ready for you to use in production. Components such as Tooltip are likely to not see any API
    changes. This is only an alpha due to the lack of documentation and a matching Sketch symbol. Feel free to share
    your experience using this component by{' '}
    <Anchor href="https://github.com/twilio-labs/paste/discussions/new" mdxType="Anchor">starting a discussion on GitHub</Anchor>. We are
    also looking for a contributor to symbolize this component in Sketch. Interested? Reach out!
  </CalloutText>
        </Callout>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Tooltip`}</h3>
        <p>{`The Tooltip component is a popup that displays text related to an element when the element is focused via
the keyboard or on mouse hover. Tooltip follows the
`}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-practices/#tooltip"
          }}>{`WAI-ARIA Tooltip Pattern`}</a>{`.`}</p>
        <h3>{`Tooltip compared to Popover`}</h3>
        <Box marginBottom="space70" mdxType="Box">
  <AspectRatio ratio="16:9" mdxType="AspectRatio">
    <iframe src="https://www.loom.com/embed/f70204bd8a4f4f21a5b1ded17f4559d1" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
  </AspectRatio>
        </Box>
        <h3>{`Accessibility`}</h3>
        <p>{`A tooltip `}<strong parentName="p">{`must only`}</strong>{` be placed on a `}<strong parentName="p">{`natively focusable`}</strong>{` HTML element. Good candidates include a `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`Paste Button`}</a>{` or a `}<a parentName="p" {...{
            "href": "/components/anchor"
          }}>{`Paste Anchor`}</a>{` if the tip also links to a help article.`}</p>
        <p><strong parentName="p">{`Do not`}</strong>{` place tooltips on non-focusable elements, like an icon.`}</p>
        <p>{`If your tooltip wraps a natively focusable HTML element that includes only an icon, make sure you set the prop `}<inlineCode parentName="p">{`decorative={false}`}</inlineCode>{`, and
give the icon a title. The title of the icon should be the accessible name for the button action, like "Delete phone number". The tooltip provides the additional context, like "You can delete phone numbers every 7 days".
This ensures the icon and tooltip are accessible to screen readers. Refer to the `}<a parentName="p" {...{
            "href": "#focusable-element"
          }}>{`focusable element example`}</a>{` for implementation.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Basic Tooltip`}</h3>
        <LivePreview scope={{
          Tooltip,
          Button
        }} mdxType="LivePreview">
  {`<Tooltip text="Black Lives Matter. We stand with the Black community.">
  <Button variant="primary">✊ Action</Button>
</Tooltip>`}
        </LivePreview>
        <h3>{`Controlling placement`}</h3>
        <p>{`The placement of the tooltip is configurable via the `}<inlineCode parentName="p">{`placement`}</inlineCode>{` prop. The available placement options are
available in the `}<a parentName="p" {...{
            "href": "#tooltip-props"
          }}>{`props description here`}</a>{`. No matter the placement, the tooltip will always adjust
to remain in the screen so you don't have to worry about it going off the page, or being covered by another element.`}</p>
        <LivePreview scope={{
          Tooltip,
          Button
        }} mdxType="LivePreview">
  {`<Tooltip text="Welcome to Paste!" placement="right">
  <Button variant="primary">Open Tooltip</Button>
</Tooltip>`}
        </LivePreview>
        <h3>{`Focusable element`}</h3>
        <p>{`A tooltip `}<strong parentName="p">{`must`}</strong>{` be placed on a `}<strong parentName="p">{`focusable`}</strong>{` element, such as an anchor or button.`}</p>
        <p><strong parentName="p">{`Do not`}</strong>{` place tooltips on non-focusable elements, like an icon. Wrap them in a focusable element and place the tooltip on that.`}</p>
        <LivePreview scope={{
          Tooltip,
          Anchor,
          Box,
          InformationIcon,
          Text
        }} mdxType="LivePreview">
  {`<Box display="flex" alignItems="center">
  <Text as="span">Social Security Number</Text>
  <Tooltip text="Your nine-digit Social Security number is your first and continuous connection with Social Security.">
    <Anchor href="https://paste.twilio.design">
      <InformationIcon decorative={false} title="Open Tooltip" display="block" />
    </Anchor>
  </Tooltip>
</Box>`}
        </LivePreview>
        <h3>{`Tooltips require their children to forwardRefs`}</h3>
        <p>{`If you want to add a Tooltip around a React component, you must make sure that component `}<a parentName="p" {...{
            "href": "https://reactjs.org/docs/forwarding-refs.html"
          }}>{`forwards refs`}</a>{`. All Paste components do this by default, so this is only a concern when placing Tooltips around other types of components.`}</p>
        <LivePreview scope={{
          Tooltip
        }} noInline language="jsx" mdxType="LivePreview">
  {NonPasteChildComponentExample}
        </LivePreview>
        <h3>{`Using state hooks`}</h3>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">🚨 Power user move! 🚨</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Only use this property if you are a power user. It's very easy to break your implementation and unfortunately the
    Paste team will not be able to debug this for you. Proceed with extreme caution.
  </CalloutText>
        </Callout>
        <p>{`The tooltip comes with the option of "hooking" into the internal state by using the state hook originally provided by
`}<a parentName="p" {...{
            "href": "https://reakit.io/docs/tooltip/#usetooltipstate"
          }}>{`Reakit`}</a>{`.`}</p>
        <p>{`Rather than the state be internal to the component, you can use the `}<inlineCode parentName="p">{`useTooltipState`}</inlineCode>{` hook and pass the returned state
to `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` as the `}<inlineCode parentName="p">{`state`}</inlineCode>{` prop.`}</p>
        <p>{`This allows you to use certain returned props from the state hook, including functions like `}<inlineCode parentName="p">{`hide`}</inlineCode>{` and `}<inlineCode parentName="p">{`show`}</inlineCode>{`.`}</p>
        <p>{`An example usecase of this might be programmatically providing the user a tooltip when a certain user action is taken.
In the example below we are showing a tooltip when another button is pressed. When pressed the button uses the `}<inlineCode parentName="p">{`show`}</inlineCode>{`
function from the hook. Another button using the `}<inlineCode parentName="p">{`hide`}</inlineCode>{` function from the hook is also provided to hide the tooltip
when pressed.`}</p>
        <p>{`It should be noted that when doing so, the state prop takes precident over the other properties that affect
the state or initial state of the Tooltip. They will be ignored in favour of them being provided as arguments
to the useTooltipState hook.`}</p>
        <p>{`For full details on how to use the state hook, and what props to provide it, follow the
`}<a parentName="p" {...{
            "href": "/primitives/tooltip-primitive"
          }}>{`Tooltip Primitive documentation`}</a>{`. It's the same hook, just renamed.`}</p>
        <LivePreview scope={{
          useTooltipState,
          Tooltip,
          Stack,
          Button
        }} noInline language="jsx" mdxType="LivePreview">
  {StateHookTabsExample}
        </LivePreview>
        <h2>{`Anatomy`}</h2>
        <h3>{`Tooltip`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body-inverse`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-border-dark`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`box-shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`max-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-top`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-bottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`z-index`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`z-index-90`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/tooltip - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Tooltip} from '@twilio-paste/tooltip';

const PopoverExample: React.FC = () => {
  return (
    <Tooltip baseId="tooltip-example" text="Tooltip text...">
      <Button variant="primary">Open Tooltip</Button>
    </Tooltip>
  );
};
`}</code></pre>
        <h4>{`Tooltip Props`}</h4>
        <h5><inlineCode parentName="h5">{`baseId?: string`}</inlineCode></h5>
        <p>{`Sets the ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`children: ReactElement`}</inlineCode></h5>
        <p>{`The tooltip reference, meaning the element where the tooltip originates. Must be a focusable element.`}</p>
        <h5><inlineCode parentName="h5">{`gutter?: string`}</inlineCode></h5>
        <p>{`Sets the offset between the reference and the tooltip on the main axis.`}</p>
        <h5><inlineCode parentName="h5">{`placement?: "auto-start" | "auto" | "auto-end" | "top-start...`}</inlineCode></h5>
        <p>{`Sets the placement of tooltip in relation to the child element. Available options include:`}</p>
        <ul>
          <li parentName="ul">{`auto-start`}</li>
          <li parentName="ul">{`auto-end`}</li>
          <li parentName="ul">{`auto`}</li>
          <li parentName="ul">{`top-start`}</li>
          <li parentName="ul">{`top-end`}</li>
          <li parentName="ul">{`top`}</li>
          <li parentName="ul">{`bottom-start`}</li>
          <li parentName="ul">{`bottom-end`}</li>
          <li parentName="ul">{`bottom`}</li>
          <li parentName="ul">{`right-start`}</li>
          <li parentName="ul">{`right-end`}</li>
          <li parentName="ul">{`right`}</li>
          <li parentName="ul">{`left-start`}</li>
          <li parentName="ul">{`left-end`}</li>
          <li parentName="ul">{`left`}</li>
        </ul>
        <h5><inlineCode parentName="h5">{`text: string`}</inlineCode></h5>
        <p>{`The text content of the Tooltip.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      